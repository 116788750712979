import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { completeInspection, importCarriers, updateInspection } from 'Actions';
import { sampleMethods } from '../models/options';
import { getInspection, getLocations, getUserProfile } from 'Selectors';
import { CompleteInspectionDialog, ImportCarriersDialog } from 'Components/Dialogs';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { Button, FieldLabel, Loading, Input, Modal } from 'Controls';
import styles from './Billing.module.css';
import { Checkbox, FormControlLabel } from '@mui/material';

const Billing = ({ locations, isLoadingCarriers, currentInspection }) => {
	const dispatch = useDispatch();
	const [showImport, setShowImport] = React.useState(false);
	const [showFinish, setShowFinish] = React.useState(false);

	const { id, inspection, rex, off, completing } = currentInspection;

	const update = React.useCallback(data => dispatch(updateInspection(id, data)), [dispatch, id]);

	if (completing) {
		return <Loading message="Completing Inspection..." />;
	}

	return (
		<div className={styles.Container}>
			<div className={styles.Columns}>
				<div className={styles.Column}>
					<div className={styles.Subtitle}>{`Commodity: ${inspection.commodityType} ${inspection.commodityGroup}`}</div>
				</div>
				<div className={styles.Column}>
					<div className={styles.Subtitle}>Offloads: {off.isFetching ? '-' : off.allIds.length}</div>
					<div className={styles.Subtitle}>Reinspections: {rex.isFetching ? '-' : rex.allIds.length}</div>
				</div>
			</div>
			<div className={styles.Columns}>
				<div className={styles.Column}>
					<FieldLabel label="Location">
						<div className="select is-medium">
							<select
								disabled={locations.byId[inspection.locationValue] && locations.byId[inspection.locationValue].deleted === true}
								name="location"
								value={inspection.locationValue}
								onChange={e => update({ locationValue: e.target.value })}>
								{locations.all.map(loc => (
									<option key={loc.locationId} value={loc.locationId} disabled={loc.deleted}>
										{loc.location} ({loc.company})
									</option>
								))}
							</select>
						</div>
					</FieldLabel>
					<FieldLabel label="Arrival">
						<DateTimePicker
							step={15}
							format="MM/DD/YYYY h:mm A"
							editFormat="MM/DD/YYYY h:mm A"
							defaultValue={inspection.arrivalTime?.toDate()}
							onChange={e => update({ arrivalTime: e })}
						/>
					</FieldLabel>
					<FieldLabel label="Departure">
						<DateTimePicker
							step={15}
							format="MM/DD/YYYY h:mm A"
							editFormat="MM/DD/YYYY h:mm A"
							defaultValue={inspection.departureTime?.toDate()}
							onChange={e => update({ departureTime: e })}
						/>
					</FieldLabel>
					<FieldLabel label="Stowage">
						<FormControlLabel
							sx={{ userSelect: 'none' }}
							label="Stowage Examination Performed?"
							control={<Checkbox checked={inspection.stowage} onChange={e => update({ stowage: e.target.checked })} />}
						/>
					</FieldLabel>
				</div>
				<div className={styles.Column}>
					<Input uppercase size="medium" label="Inspector" value={inspection.inspector} onBlur={e => update({ inspector: e.target.value })} />
					<Input uppercase size="medium" label="Sampler" value={inspection.sampler} onBlur={e => update({ sampler: e.target.value })} />
					<FieldLabel label="Sample Method">
						<div className="select is-medium">
							<select value={inspection.sampleMethod} onChange={e => update({ sampleMethod: e.target.value })}>
								{sampleMethods.map(method => (
									<option key={method} value={method}>
										{method}
									</option>
								))}
							</select>
						</div>
					</FieldLabel>
					<Input size="medium" label="Probe Length" value={inspection.sampleProbeDepth} onBlur={e => update({ sampleProbeDepth: e.target.value })} />
				</div>
			</div>
			<div className={styles.Columns}>
				<div className={styles.Column}>
					<Button
						fullWidth
						size="large"
						color="danger"
						loading={isLoadingCarriers}
						disabled={isLoadingCarriers}
						onClick={() => {
							if (!isLoadingCarriers && inspection.departureTime) {
								setShowFinish(true);
							}
						}}>
						Finish Inspection
					</Button>
				</div>
				<div className={styles.Column}>
					<Button size="large" onClick={() => setShowImport(true)}>
						Import Carrier List
					</Button>
				</div>
			</div>
			<Modal show={showFinish}>
				<CompleteInspectionDialog onCancel={() => setShowFinish(false)} onAccept={sendEmails => dispatch(completeInspection(sendEmails))} />
			</Modal>
			<Modal show={showImport}>
				<ImportCarriersDialog onCancel={() => setShowImport(false)} onAccept={list => dispatch(importCarriers(id, list))} />
			</Modal>
		</div>
	);
};

const mapStateToProps = state => {
	const currentInspection = getInspection(state);
	return {
		currentInspection,
		locations: getLocations(state),
		isInspector: getUserProfile(state).isInsp,
		isLoadingCarriers: currentInspection.rex.isFetching || currentInspection.off.isFetching || currentInspection.carriers.isFetching
	};
};

export default connect(mapStateToProps)(Billing);
