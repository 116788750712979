import { SET_CONFIG } from '../Actions/types';

const config = (state = {}, action) => {
	switch (action.type) {
		case SET_CONFIG:
			return action.data ?? {};
		default:
			return state;
	}
};

export default config;
