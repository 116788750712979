import React from 'react';
import { connect } from 'react-redux';
import { setFactorScroll, updateCarrierInspection } from 'Actions';
import FactorDisplayNames from '../models/factors/names';
import { getInspection, getCarrierInspection, getFactors } from 'Selectors';
import { calculatePercentMulti } from '../lib/helpers';
import without from 'lodash/without';
import { Button, DecimalInput } from 'Controls';
import styles from './MultiFactor.module.css';

class MultiFactor extends React.Component {
	constructor(props) {
		super(props);

		const factors = props.currentFactor.factor.factors.reduce((acc, f) => {
			const factor = props.factors[f];
			if (factor.type === 'percent') {
				return {
					...acc,
					[f]: props.currentCarrier.carrier[f] || '0.00',
					['w' + factor.sampleSize]: props.currentCarrier.carrier['w' + factor.sampleSize] || ''
				};
			} else if (factor.type === 'select') {
				return { ...acc, [f]: props.currentCarrier.carrier[f] || [] };
			} else {
				return { ...acc, [f]: props.currentCarrier.carrier[f] || '' };
			}
		}, {});

		const extras = props.currentFactor.factor.extras?.reduce((acc, f) => ({ ...acc, [f]: props.currentCarrier.carrier[f] || '' }), {});

		this.state = {
			factors,
			extras
		};
	}

	render() {
		const factorData = this.props.factors;

		return (
			<div className={styles.Root}>
				<div className="container">
					<h1 className="title">{FactorDisplayNames[this.props.currentFactor.name]}</h1>
					<div className="columns">
						<div className="column">
							{this.props.currentFactor.factor.factors.map((f, index) => {
								if (factorData[f].type === 'percent') {
									const percent = calculatePercentMulti(
										this.state.factors[f],
										this.state.factors['w' + this.props.factors[f].sampleSize],
										factorData[f].truncate || 2
									);
									return (
										<React.Fragment key={index}>
											<div className="field">
												<label className="label">Total Grams</label>
												<div className="control">
													<input
														className="input"
														type="number"
														value={this.state.factors['w' + factorData[f].sampleSize]}
														onFocus={e => this.setState({ input: f + '_total' })}
														onChange={e =>
															this.setState({
																factors: {
																	...this.state.factors,
																	['w' + factorData[f].sampleSize]: e.target.value
																}
															})
														}
													/>
												</div>
											</div>
											<div className="field">
												<label className="label">{FactorDisplayNames[f]} Grams</label>
												<div className="control">
													<DecimalInput
														precision={2}
														onFocus={e => this.setState({ input: f + '_part' })}
														value={this.state.factors[f]}
														onChange={e => this.setState({ factors: { ...this.state.factors, [f]: e } })}
													/>
												</div>
											</div>
											<h1 className="title">{percent} %</h1>
										</React.Fragment>
									);
								}
								if (factorData[f].type === 'select') {
									return (
										<React.Fragment key={index}>
										<div className="field">
											<label className="label">{FactorDisplayNames[f]}</label>
											<div className={styles.Buttons}>
												{factorData[f].options.map(opt => (
													<Button
														key={opt}
														size="large"
														color={this.state.factors[f].includes(opt) && 'primary'}
														onClick={() => {
															if (this.state.factors[f].includes(opt))
																this.setState({ factors: { ...this.state.factors, [f]: without(this.state.factors[f], opt) } });
															else this.setState({ factors: { ...this.state.factors, [f]: [...this.state.factors[f], opt] } });
														}}>
														{opt}
													</Button>
												))}
											</div>
										</div>
										</React.Fragment>
									);
								}
								return (
									<React.Fragment key={index}>
									<div className="field">
										<label className="label">{FactorDisplayNames[f]}</label>
										<div className="control">
											<input
												autoFocus={index === 0}
												className="input"
												type="number"
												value={this.state.factors[f]}
												onChange={e => this.setState({ factors: { ...this.state.factors, [f]: e.target.value } })}
											/>
										</div>
									</div>
									</React.Fragment>
								);
							})}
							<div className="buttons">
								<Button
									size="large"
									color="primary"
									disabled={Object.keys(this.state.factors).filter(f => !this.state.factors[f] || this.state.factors[f] === '').length > 0}
									onClick={() => {
										const data = { ...this.state.extras, ...this.state.factors };
										this.props.updateCarrierInspection(this.props.currentInspection.carrierId, data);
										this.props.onAccept && this.props.onAccept(data);
									}}>
									Confirm
								</Button>
								<Button size="large" onClick={() => this.props.onCancel()}>
									Cancel
								</Button>
							</div>
						</div>
						{this.props.currentFactor.factor.extras && (
							<div className="column">
								{this.props.currentFactor.factor.extras?.map(f => (
									<div className={styles.Horizontal}>
										<label className="label">{FactorDisplayNames[f]}</label>
										<input
											className="input"
											type="number"
											value={this.state.extras[f]}
											onChange={e => this.setState({ extras: { ...this.state.extras, [f]: e.target.value } })}
										/>
									</div>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		currentInspection: getInspection(state),
		currentCarrier: getCarrierInspection(state),
		factors: getFactors(state),
		currentFactor: state.currentFactor
	};
};

export default connect(mapStateToProps, {
	updateCarrierInspection,
	setFactorScroll
})(MultiFactor);
