import { createSelector } from 'reselect';
import getCommodityFactors from './factors';
import { CalculateSingleCarrier } from '../models/grading';

const currentInspectionSelector = state => state.inspections.byId[state.currentInspection.id];
const currentCarrierSelector = state => state.currentInspection.carriers.byId[state.currentInspection.carrierId];

const getCurrentCarrierInspection = (inspection, carrier, factors) => {
	return {
		carrier: carrier,
		calculatedCarrier: CalculateSingleCarrier(carrier, inspection, factors)
	};
};

export default createSelector([currentInspectionSelector, currentCarrierSelector, getCommodityFactors], getCurrentCarrierInspection);
