import React from 'react';
import { CircularProgress } from '@mui/material';
import styles from './Loading.module.css';

const Loading = ({ message }) => (
	<div className={styles.Container}>
		{message && <div className={styles.Message}>{message}</div>}
		<CircularProgress color="inherit" />
	</div>
);

export default Loading;
