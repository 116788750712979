import React from 'react';
import { createRoot } from 'react-dom/client';
import { Button } from '@mui/material';
import { enqueueSnackbar, closeSnackbar } from 'Actions';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reducers from 'Reducers';
import { thunk } from 'redux-thunk';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import * as serviceWorker from './serviceWorkerRegistration';
import { App } from 'Components';
import 'react-widgets/dist/css/react-widgets.css';
import 'react-virtualized/styles.css';
import './bulma.sass';
import './index.css';

moment.locale('en');
momentLocalizer();

const store = createStore(reducers, applyMiddleware(thunk));

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<Provider store={store}>
		<App />
	</Provider>
);

const updateServiceWorker = reg => {
	if (reg.waiting) {
		reg.waiting.postMessage({ type: 'SKIP_WAITING' });
		reg.waiting.addEventListener('statechange', e => {
			if (e.target.state === 'activated') {
				window.location.reload();
			}
		});
	}
};

serviceWorker.register({
	onUpdate: reg =>
		store.dispatch(
			enqueueSnackbar({
				message: 'Update available',
				options: {
					persist: true,
					action: key => (
						<Button
							onClick={() => {
								store.dispatch(closeSnackbar(key));
								updateServiceWorker(reg);
							}}
							color="inherit">
							Update
						</Button>
					)
				}
			})
		)
});
