const Corn = {
	testWeight: {
		isCalculated: false,
		scroll: '56.0'
	},
	moisture: {
		isCalculated: false,
		scroll: '14.0'
	},
	damage: {
		isCalculated: true,
		sampleSize: '250',
		truncate: 2
	},
	bcfm: {
		isCalculated: true,
		sampleSize: '1050',
		truncate: 2
	},
	heat: {
		isCalculated: true,
		sampleSize: '250',
		allowZero: true,
		spinner: false,
		default: '0.0',
		truncate: 2
	},
	ocol: {
		allowEmpty: true,
		isCalculated: true,
		sampleSize: '250',
		spinner: false,
		default: '',
		truncate: 2
	}
};

const Wheat = {
	testWeight: {
		isCalculated: false,
		scroll: '56.0'
	},
	moisture: {
		isCalculated: false,
		scroll: '14.0'
	},
	dockage: {
		isCalculated: true,
		sampleSize: '1050'
	},
	sbk: {
		isCalculated: true,
		sampleSize: '250'
	},
	fm: {
		isCalculated: true,
		sampleSize: '60',
		allowZero: true,
		spinner: false,
		default: '0.0'
	},
	heat: {
		isCalculated: true,
		sampleSize: '60',
		allowZero: true,
		spinner: false,
		default: '0.0'
	},
	damage: {
		isCalculated: true,
		sampleSize: '15a'
	},
	wocl: {
		isCalculated: true,
		sampleSize: '15b',
		spinner: false,
		default: '0.0'
	},
	cc: {
		isCalculated: true,
		sampleSize: '15b',
		spinner: false,
		default: '0.0'
	}
};

const Soybeans = {
	testWeight: {
		isCalculated: false,
		scroll: '56.0'
	},
	moisture: {
		isCalculated: false,
		scroll: '12.5'
	},
	fm: {
		isCalculated: true,
		type: 'multi',
		factors: ['fmCoarse', 'fmFine']
	},
	damage: {
		isCalculated: true,
		sampleSize: '125-fines'
	},
	heat: {
		isCalculated: true,
		sampleSize: '125-fines',
		allowZero: true,
		spinner: false,
		default: '0.0'
	},
	splits: {
		isCalculated: true,
		sampleSize: '125-fines'
	},
	sboc: {
		allowEmpty: true,
		isCalculated: true,
		sampleSize: '125-fines',
		spinner: false,
		default: ''
	}
};

export default { Corn, Soybeans, Wheat };
