import React from 'react';
import { GetInspectionExportData, GetClientEmailData } from 'lib/dataHelper';
import { GetTeamData } from 'lib/teamHelper';
import { auth, firestore, functions } from 'lib/firebase';
import { onAuthStateChanged, sendPasswordResetEmail, signOut } from 'firebase/auth';
import { collection, getDoc, getDocs, where, doc, setDoc, updateDoc, onSnapshot, query, deleteDoc, addDoc, writeBatch, deleteField } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { createLocation, createInspection, createCarrier, insertIf, quoteString } from 'lib/helpers';
import { Abbreviations } from 'models/factors/abbreviations';
import { Button } from '@mui/material';
import * as types from './types';

const Inspections = collection(firestore, 'inspections');
const CarrierInspections = collection(firestore, 'carriers');
const RexCarriers = collection(firestore, 'rexCarriers');
const OffCarriers = collection(firestore, 'offCarriers');
const Users = collection(firestore, 'users');
const Locations = collection(firestore, 'locations');
const PendingDocuments = collection(firestore, 'pendingDocuments');

const func_sendClientEmails = httpsCallable(functions, 'sendClientEmails');
const func_setUserPassword = httpsCallable(functions, 'setUserPassword');
const func_setCurrentUserPassword = httpsCallable(functions, 'setCurrentUserPassword');
const func_disableUser = httpsCallable(functions, 'disableUser');
const func_enableUser = httpsCallable(functions, 'enableUser');
const func_createUser = httpsCallable(functions, 'createUser');
const func_reassignInspection = httpsCallable(functions, 'reassignInspection');

let inspectionsListener = null;
let archivesListener = null;
let carriersListener = null;
let rexCarriersListener = null;
let offCarriersListener = null;
let usersListener = null;
let locationsListener = null;

/****************************************************************************
 * Inspection INIT Actions
 ***************************************************************************/

export const fetchIncompleteInspections = () => dispatch => {
	dispatch({ type: types.REQUEST_INSPECTIONS });

	let init = true;

	(inspectionsListener || Function)();

	const q = query(
		Inspections,
		where('archived', '==', false),
		where('deleted', '==', false),
		where('complete', '==', false)
	);

	inspectionsListener = onSnapshot(q, snapshot => {
		snapshot.docChanges().forEach(({ doc, type }) => {
			switch (type) {
				case 'added':
					dispatch({ type: types.RECEIVE_INSPECTION_ADDED, id: doc.id, added: doc.data() });
					break;
				case 'modified':
					dispatch({ type: types.RECEIVE_INSPECTION_MODIFIED, id: doc.id, modified: doc.data() });
					break;
				case 'removed':
					dispatch({ type: types.RECEIVE_INSPECTION_REMOVED, id: doc.id, removed: doc.data() });
					break;
				default:
					break;
			}
		});
		if (init) dispatch({ type: types.REQUEST_INSPECTIONS_FINISHED });

		init = false;
	});
};

export const fetchClientInspections = user => dispatch => {
	dispatch({ type: types.REQUEST_INSPECTIONS });

	let init = true;

	(inspectionsListener || Function)();

	const q = query(
		Inspections,
		where('archived', '==', false),
		where('deleted', '==', false)
	);

	inspectionsListener = onSnapshot(q, snapshot => {
			snapshot.docChanges().forEach(({ doc, type }) => {
				const data = doc.data();

				if (user.locations.includes(data.locationValue)) {
					switch (type) {
						case 'added':
							dispatch({ type: types.RECEIVE_INSPECTION_ADDED, id: doc.id, added: data });
							break;
						case 'modified':
							dispatch({ type: types.RECEIVE_INSPECTION_MODIFIED, id: doc.id, modified: data });
							break;
						case 'removed':
							dispatch({ type: types.RECEIVE_INSPECTION_REMOVED, id: doc.id, removed: data });
							break;
						default:
							break;
					}
				}
			});
			if (init) dispatch({ type: types.REQUEST_INSPECTIONS_FINISHED });

			init = false;
		});
};

export const fetchAllInspections = () => dispatch => {
	dispatch({ type: types.REQUEST_INSPECTIONS });

	let init = true;

	(inspectionsListener || Function)();

	const q = query(
		Inspections,
		where('archived', '==', false)
	);

	inspectionsListener = onSnapshot(q, snapshot => {
		snapshot.docChanges().forEach(({ doc, type }) => {
			switch (type) {
				case 'added':
					dispatch({ type: types.RECEIVE_INSPECTION_ADDED, id: doc.id, added: doc.data() });
					break;
				case 'modified':
					dispatch({ type: types.RECEIVE_INSPECTION_MODIFIED, id: doc.id, modified: doc.data() });
					break;
				case 'removed':
					dispatch({ type: types.RECEIVE_INSPECTION_REMOVED, id: doc.id, removed: doc.data() });
					break;
				default:
					break;
			}
		});
		if (init) dispatch({ type: types.REQUEST_INSPECTIONS_FINISHED });

		init = false;
	});
};

export const fetchArchives = () => dispatch => {
	dispatch({ type: types.REQUEST_ARCHIVES });

	let init = true;

	(archivesListener || Function)();

	const q = query(
		Inspections,
		where('archived', '==', true)
	);

	archivesListener = onSnapshot(q, snapshot => {
		snapshot.docChanges().forEach(({ doc, type }) => {
			switch (type) {
				case 'added':
					dispatch({ type: types.RECEIVE_ARCHIVE_ADDED, id: doc.id, added: doc.data() });
					break;
				case 'modified':
					dispatch({ type: types.RECEIVE_ARCHIVE_MODIFIED, id: doc.id, modified: doc.data() });
					break;
				case 'removed':
					dispatch({ type: types.RECEIVE_ARCHIVE_REMOVED, id: doc.id, removed: doc.data() });
					break;
				default:
					break;
			}
		});
		if (init) dispatch({ type: types.REQUEST_ARCHIVES_FINISHED });

		init = false;
	});
};

/****************************************************************************
 * Carrier INIT Actions
 ***************************************************************************/

export const fetchCarrierInspections = inspectionId => dispatch => {
	dispatch({ type: types.REQUEST_CARRIERS });
	dispatch({ type: types.REQUEST_REX });
	dispatch({ type: types.REQUEST_OFF });

	removeCarrierListener();

	let initCarriers = true;
	let initRex = true;
	let initOff = true;

	const q = query(
		CarrierInspections,
		where('inspectionId', '==', inspectionId)
	);
	const qRex = query(
		RexCarriers,
		where('inspectionId', '==', inspectionId)
	);
	const qOff = query(
		OffCarriers,
		where('inspectionId', '==', inspectionId)
	);

	carriersListener = onSnapshot(q, snapshot => {
		snapshot.docChanges().forEach(({ doc, type }) => {
			switch (type) {
				case 'added':
					dispatch({ type: types.RECEIVE_CARRIER_ADDED, id: doc.id, added: doc.data() });
					break;
				case 'modified':
					dispatch({ type: types.RECEIVE_CARRIER_MODIFIED, id: doc.id, modified: doc.data() });
					break;
				case 'removed':
					dispatch({ type: types.RECEIVE_CARRIER_REMOVED, id: doc.id, removed: doc.data() });
					break;
				default:
					break;
			}
		});
		if (initCarriers) dispatch({ type: types.REQUEST_CARRIERS_FINISHED });

		initCarriers = false;
	},
		error => dispatch(firebaseError(error, 'Failed to fetch carriers'))
	);

	rexCarriersListener = onSnapshot(qRex, snapshot => {
		snapshot.docChanges().forEach(({ doc, type }) => {
			switch (type) {
				case 'added':
					dispatch({ type: types.RECEIVE_REX_ADDED, id: doc.id, added: doc.data() });
					break;
				case 'modified':
					dispatch({ type: types.RECEIVE_REX_MODIFIED, id: doc.id, modified: doc.data() });
					break;
				case 'removed':
					dispatch({ type: types.RECEIVE_REX_REMOVED, id: doc.id, removed: doc.data() });
					break;
				default:
					break;
			}
		});
		if (initRex) dispatch({ type: types.REQUEST_REX_FINISHED });

		initRex = false;
	},
		error => dispatch(firebaseError(error, 'Failed to fetch carriers (REX)'))
	);

	offCarriersListener = onSnapshot(qOff, snapshot => {
		snapshot.docChanges().forEach(({ doc, type }) => {
			switch (type) {
				case 'added':
					dispatch({ type: types.RECEIVE_OFF_ADDED, id: doc.id, added: doc.data() });
					break;
				case 'modified':
					dispatch({ type: types.RECEIVE_OFF_MODIFIED, id: doc.id, modified: doc.data() });
					break;
				case 'removed':
					dispatch({ type: types.RECEIVE_OFF_REMOVED, id: doc.id, removed: doc.data() });
					break;
				default:
					break;
			}
		});
		if (initOff) dispatch({ type: types.REQUEST_OFF_FINISHED });

		initOff = false;
	},
		error => dispatch(firebaseError(error, 'Failed to fetch carriers (OFF)'))
	);
};

/****************************************************************************
 * User INIT Actions
 ***************************************************************************/

export const fetchUsers = () => dispatch => {
	dispatch({ type: types.REQUEST_USERS });

	let init = true;

	if (usersListener !== null) usersListener();

	usersListener = onSnapshot(query(Users), snapshot => {
		snapshot.docChanges().forEach(({ doc, type }) => {
			switch (type) {
				case 'added':
					dispatch({ type: types.RECEIVE_USER_ADDED, id: doc.id, added: doc.data() });
					break;
				case 'modified':
					dispatch({ type: types.RECEIVE_USER_MODIFIED, id: doc.id, modified: doc.data() });
					break;
				case 'removed':
					dispatch({ type: types.RECEIVE_USER_REMOVED, id: doc.id, removed: doc.data() });
					break;
				default:
					break;
			}
		});
		if (init) dispatch({ type: types.REQUEST_USERS_FINISHED });

		init = false;
	});
};

/****************************************************************************
 * Location INIT Actions
 ***************************************************************************/

export const fetchLocations = () => dispatch => {
	dispatch({ type: types.REQUEST_LOCATIONS });

	let init = true;

	(locationsListener || Function)();

	locationsListener = onSnapshot(query(Locations), snapshot => {
		snapshot.docChanges().forEach(({ doc, type }) => {
			switch (type) {
				case 'added':
					dispatch({ type: types.RECEIVE_LOCATION_ADDED, id: doc.id, added: doc.data() });
					break;
				case 'modified':
					dispatch({ type: types.RECEIVE_LOCATION_MODIFIED, id: doc.id, modified: doc.data() });
					break;
				case 'removed':
					dispatch({ type: types.RECEIVE_LOCATION_REMOVED, id: doc.id, removed: doc.data() });
					break;
				default:
					break;
			}
		});
		if (init) dispatch({ type: types.REQUEST_LOCATIONS_FINISHED });

		init = false;
	});
};

/****************************************************************************
 * Inspection Actions
 ***************************************************************************/

export const newInspection = () => (dispatch, getState) => {
	const locations = getState().locations;
	const locationValue = locations.allIds.find(i => locations.byId[i].isDefault === true && locations.byId[i].deleted !== true);

	dispatch(addInspection(createInspection({ locationValue })));
};

export const addInspection = inspection => async dispatch => {
	dispatch({ type: types.ADD_INSPECTION, inspection });
	try {
		const newInspection = await addDoc(Inspections, { ...inspection, createdBy: auth.currentUser.email });
		dispatch(setCurrentInspection(newInspection.id));
		dispatch(changeView('inspection'));
		dispatch({ type: types.INSPECTION_ADDED });
	} catch (error) {
		dispatch(firebaseError(error, 'Failed to add inspection'));
	}
};

export const deleteInspection = id => dispatch => {
	try {
		updateDoc(doc(Inspections, id), { deleted: true, deletedDate: new Date() });
		dispatch(
			enqueueUndoSnackbar({ message: 'Inspection deleted' }, () => dispatch(undeleteInspection(id)))
		);
	} catch (error) {
		dispatch(firebaseError(error, 'Failed to delete inspection'));
	}
};

export const undeleteInspection = id => dispatch => {
	try {
		updateDoc(doc(Inspections, id), { deleted: false, deletedDate: deleteField() });
	} catch (error) {
		dispatch(firebaseError(error, 'Failed to restore inspection'));
	}
};

export const updateInspection = (id, props) => dispatch => {
	try {
		updateDoc(doc(Inspections, id), props);
	} catch (error) {
		dispatch(firebaseError(error, `Failed to update inspection data (ID: ${id})`));
	}
};

export const setCurrentInspection = id => (dispatch, getState) => {
	removeCarrierListener();
	const state = getState();

	updateDoc(doc(Users, state.user.auth.email), { prevInspectionId: id });

	if (id !== null) {
		dispatch({ type: types.SET_CURRENT_INSPECTION, id });
		dispatch(fetchCarrierInspections(id));
	} else {
		dispatch({ type: types.SET_CURRENT_INSPECTION, id: null });
	}
};

/****************************************************************************
 * Carrier Inspection Actions
 ***************************************************************************/

export const newCarrierInspection = (inspectionId, orderId, inspector) => dispatch => dispatch(addCarrier(createCarrier({ inspectionId, orderId, inspector })));

export const addCarrier = (carrier) => async (dispatch, getState) => {
	dispatch({ type: types.ADD_CARRIER, carrier });
	const state = getState();

	try {
		const newCarrier = doc(CarrierInspections);
		await setDoc(newCarrier, { ...carrier, createdBy: state.user.auth.uid });

		dispatch(setCurrentCarrier(newCarrier.id));
		dispatch(changeInspectionView('inspection'));
		dispatch(changeCarrierView('carrier'));
		dispatch({ type: types.CARRIER_ADDED });
	} catch (error) {
		dispatch(firebaseError(error, 'Failed to add carrier'));
	}
};

export const updateCarrierFactors = (id, factors) => (dispatch, getState) => {
	const state = getState();
	if (state.currentInspection.carriers.byId[id].rexId !== null) {
		let changed = Object.keys(factors).reduce((acc, f) => ({ ...acc, [`changed.${f}`]: true }), {});
		dispatch(updateCarrierInspection(id, Object.assign({}, factors, changed)));
	} else {
		dispatch(updateCarrierInspection(id, factors));
	}
};

export const updateCarrierInspection = (id, props) => dispatch => {
	try {
		updateDoc(doc(CarrierInspections, id), props);
	} catch (error) {
		dispatch(firebaseError(error, `Failed to update carrier data (ID: ${id})`));
	}
};

export const setCurrentCarrier = id => ({ type: types.SET_CURRENT_CARRIER, id });

export const removeCarrierListener = () => {
	(carriersListener || Function)();
	(rexCarriersListener || Function)();
	(offCarriersListener || Function)();
};

export const removeAllListeners = () => {
	(inspectionsListener || Function)();
	(archivesListener || Function)();
	(usersListener || Function)();
	(locationsListener || Function)();
	(carriersListener || Function)();
	(rexCarriersListener || Function)();
	(offCarriersListener || Function)();
};

/****************************************************************************
 * REX Carrier Actions
 ***************************************************************************/
export const newRexCarrierInspection = id => async dispatch => {
	const carrier = doc(CarrierInspections, id);

	try {
		const d = await getDoc(carrier);

		if (d.exists()) {
			const rexCarrier = await addDoc(RexCarriers, d.data());
			updateDoc(carrier, { rexId: rexCarrier.id });
		}
	} catch (error) {
		dispatch(genericError(error));
	}
};

export const updateRexCarrier = (id, props) => dispatch => {
	try {
		updateDoc(doc(RexCarriers, id), props);
	} catch (error) {
		dispatch(firebaseError(error, `Failed to update REX carrier data (ID: ${id})`));
	}
};

/****************************************************************************
 * OFF Carrier Actions
 ***************************************************************************/
export const newOffCarrierInspection = id => async dispatch => {
	const carrier = doc(CarrierInspections, id);

	try {
		const d = await getDoc(carrier);

		if (d.exists()) {
			const data = d.data();

			const offCarrier = await addDoc(OffCarriers, data);

			const newCarrier = await addDoc(CarrierInspections,
				createCarrier({
					inspectionId: data.inspectionId,
					carNumber: data.carNumber,
					offId: offCarrier.id,
					orderId: data.orderId
				})
			);

			dispatch(setCurrentCarrier(newCarrier.id));
			dispatch(changeCarrierView('carrier'));
			dispatch(changeInspectionView('inspection'));

			deleteDoc(carrier);
		}
	} catch (error) {
		dispatch(genericError(error));
	}
};

export const offloadSingleCompositeCarrier = (id, index) => async dispatch => {
	const carrier = doc(CarrierInspections, id);

	try {
		const d = await getDoc(carrier);

		if (d.exists()) {
			const { composite, ...otherData } = d.data();
			const offCarrier = await addDoc(OffCarriers, { ...otherData, ...composite[index] });
			const newCarrier = await addDoc(CarrierInspections,
				createCarrier({
					inspectionId: otherData.inspectionId,
					inspector: otherData.inspector,
					orderId: otherData.orderId,
					composite: { ...composite, [index]: { offId: offCarrier.id, carNumber: composite[index]?.carNumber } }
				})
			);

			dispatch(setCurrentCarrier(newCarrier.id));

			deleteDoc(carrier);
		}
	} catch (error) {
		dispatch(genericError(error));
	}
};

export const offloadCompositeCarrier = id => async dispatch => {
	const carrier = doc(CarrierInspections, id);

	try {
		const d = await getDoc(carrier);

		if (d.exists()) {
			const data = d.data();
			const offCarrier = await addDoc(OffCarriers, data);

			const newCarrier = await addDoc(CarrierInspections,
				createCarrier({
					inspectionId: data.inspectionId,
					offId: offCarrier.id,
					orderId: data.orderId,
					composite: Object.keys(data.composite).reduce(
						(acc, i) => ({ ...acc, [i]: { netWeight: '', carNumber: data.composite[i]?.carNumber } }), //...data.composite[i],
						{}
					)
				})
			);

			dispatch(setCurrentCarrier(newCarrier.id));

			deleteDoc(carrier);
		}
	} catch (error) {
		dispatch(genericError(error));
	}
};

export const updateOffCarrier = (id, props) => dispatch => {
	try {
		updateDoc(doc(OffCarriers, id), props);
	} catch (error) {
		dispatch(firebaseError(error, `Failed to update OFF carrier data (ID: ${id})`));
	}
};

/****************************************************************************/
//region User Actions
/****************************************************************************/

export const addUser = user => async dispatch => {
	dispatch({ type: types.ADD_USER, user });
	user.email = user.email.toLowerCase();

	const key = new Date().getTime() + Math.random();
	dispatch(enqueueSnackbar({ message: 'Creating user...', options: { key, persist: true, action: () => null } }));

	func_createUser(user)
		.then(res => {
			if (res.data.success) {
				dispatch(enqueueSnackbar({ message: 'User created', options: { variant: 'success' } }));
			} else {
				throw res.data.message;
			}
		})
		.catch(error => {
			dispatch(enqueueSnackbar({ message: 'Failed to create user', options: { variant: 'error' } }));
		})
		.finally(() => {
			dispatch(closeSnackbar(key));
		});
};

export const deleteUser = id => dispatch => {
	try {
		deleteDoc(doc(Users, id));
	} catch (error) {
		dispatch(firebaseError(error, 'Failed to delete user'));
	}
};

export const updateUser = (id, props) => dispatch => {
	try {
		updateDoc(doc(Users, id), props);
	} catch (error) {
		dispatch(firebaseError(error, 'Failed to update user'));
	}
};

export const setCurrentUser = user => ({ type: types.SET_CURRENT_USER, user });

//endregion

/****************************************************************************/
//region Location Actions
/****************************************************************************/

export const addLocation = (id, data) => async dispatch => {
	const ref = doc(Locations, id);
	const d = await getDoc(ref);

	if (d.exists()) {
		dispatch(genericError('Location ID already in use'));
		return;
	}

	setDoc(ref, createLocation(data))
		.catch(error => dispatch(firebaseError(error, 'Failed to add location')));
};

export const updateLocation = (id, data) => dispatch => {
	const ref = doc(Locations, id);

	updateDoc(ref, data)
		.catch(error => dispatch(firebaseError(error, 'Failed to update location data')));
};

export const deleteLocation = id => dispatch => {
	const ref = doc(Locations, id);

	updateDoc(ref, { deleted: true, deletedDate: new Date() })
		.catch(error => dispatch(firebaseError(error, 'Failed to delete location')));
};

/****************************************************************************/
//#region Error Handling Actions
/****************************************************************************/

export const firebaseError = (error, message) => dispatch => {
	const errMessage = `${error.name} (${error.code}):\r\n${message}`;

	dispatch({ type: types.GENERIC_ERROR, error: errMessage });
	dispatch(enqueueSnackbar({ message: errMessage, options: { variant: 'error' } }));
};

export const genericError = error => dispatch => {
	const errMessage = error.toString();
	dispatch({ type: types.GENERIC_ERROR, error: errMessage });
	dispatch(enqueueSnackbar({ message: `Error: ${errMessage}`, options: { variant: 'error' } }));
};

//#endregion

/****************************************************************************
 * Auth Actions
 ***************************************************************************/

export const initAuth = () => dispatch => {
	dispatch({ type: types.INIT_AUTH });
	onAuthStateChanged(auth, user => {
		dispatch({ type: types.UPDATE_AUTH, auth: user });
		if (user) {
			dispatch(fetchCurrentUser(user));
		} else {
			dispatch({ type: types.AUTH_DESTROYED });
		}
	});
};
export const logout = () => async dispatch => {
	try {
		await signOut(auth);
		dispatch({ type: types.AUTH_DESTROYED });
		removeAllListeners();
	} catch (error) {
		dispatch({ type: types.AUTH_ERROR, error });
	}
};

/****************************************************************************
 * View Actions
 ***************************************************************************/

export const changeView = view => ({ type: types.CHANGE_VIEW, view });

export const changeInspectionView = view => ({ type: types.CHANGE_INSPECTION_VIEW, view });

export const changeCarrierView = view => ({ type: types.CHANGE_CARRIER_VIEW, view });

export const setCurrentFactor = (name, factor, values) => ({ type: types.SET_CURRENT_FACTOR, name, factor, values });

export const setFactorScroll = (factor, value) => ({ type: types.SET_FACTOR_SCROLL, factor, value });

export const fetchCurrentUser = auth => dispatch => {
	const ref = doc(Users, auth.email);

	getDoc(ref)
		.then(function (doc) {
			if (doc.exists()) {
				dispatch(initUserState(doc.data()));
				updateDoc(ref, { lastLogin: new Date() });
			} else {
				signOut(auth);
				dispatch(genericError('The required user data does not exist.'));
			}
		})
		.catch(error => dispatch(genericError(error)));
};

const initUserState = user => dispatch => {
	dispatch(setCurrentUser(user));

	dispatch(changeView('grid'));
	dispatch(fetchLocations());

	if (user.role === 'Inspector') {
		dispatch(fetchIncompleteInspections());
		dispatch(changeView('inspection'));

		if (user.prevInspectionId) {
			dispatch(setCurrentInspection(user.prevInspectionId));
		}
	} else if (user.role === 'Client') {
		dispatch(fetchClientInspections(user));
	} else {
		dispatch(fetchAllInspections());
	}

	if (user.role === 'Admin') {
		dispatch(fetchUsers());
	}

	dispatch(initialize());
};

export const initialize = () => ({ type: types.APP_INITIALIZED });

export const completeInspection = sendEmails => async (dispatch, getState) => {
	dispatch({ type: types.COMPLETING_INSPECTION });

	const state = getState();

	const data = GetInspectionExportData(state);
	const teamData = await GetTeamData(state);

	try {
		await addDoc(PendingDocuments, { docStatus: 'pending', ...data, teamData, sendEmails, createdBy: state.user.auth.email, createdAt: new Date() });
	} catch (error) {
		dispatch(firebaseError(error, 'Failed to send document for generation'));
		dispatch({ type: types.COMPLETING_INSPECTION_FAILED, error: 'Failed to complete inspection' });
		return;
	}
	try {
		await updateDoc(doc(Inspections, state.currentInspection.id), { complete: true });
		dispatch(changeView('grid'));
		dispatch(setCurrentInspection(null));
	} catch (error) {
		dispatch(firebaseError(error, 'Failed to update inspection data'));
		dispatch({ type: types.COMPLETING_INSPECTION_FAILED, error: 'Failed to update inspection data' });
		return;
	}

	dispatch({ type: types.INSPECTION_COMPLETE });
};

export const importCarriers = (inspectionId, list) => dispatch => {
	dispatch({ type: types.IMPORTING_CARRIERS });

	let batch = writeBatch(firestore);

	for (let i = 0; i < list.length; i++) {
		let ref = doc(CarrierInspections);
		batch.set(ref, createCarrier({ inspectionId: inspectionId, carNumber: list[i], orderId: i + 1 }));
	}

	batch
		.commit()
		.then(result => dispatch({ type: types.CARRIERS_IMPORTED }))
		.catch(error => firebaseError(error, 'Failed to batch import carriers'));
};

export function createDocument(status, data) {
	return dispatch => {
		addDoc(PendingDocuments, { docStatus: status, ...data })
			.catch(error => dispatch(firebaseError(error, 'Failed to send document for generation')));
	};
}

export const sendPasswordReset = email => dispatch => {
	sendPasswordResetEmail(auth, email).then(() => {
		dispatch(enqueueSnackbar({ message: 'Password reset sent', options: { variant: 'success' } }));
	});
};

//TODO: Make dispatch function?
export function restoreInspection(id) {
	return new Promise((resolve, reject) => {
		const ref = doc(Inspections, id);

		getDoc(ref)
			.then(doc => {
				if (!doc.exists()) {
					return resolve(null);
				}

				if (doc.data().archived === false) {
					return resolve(false);
				}

				updateDoc(ref, { archived: false, archivedDate: deleteField() })
					.then(() => {
						return resolve(true);
					});
			});
	});
}

export const reassignInspection = (id, user) => dispatch => {
	try {
		updateDoc(doc(Users, user), { prevInspectionId: id });
		dispatch({ type: 'INSPECTION_REASSIGNED', id, user });
	} catch (error) {
		dispatch(genericError('Failed to reassign inspection'));
	}
};

export const setUserPassword = (email, password) => dispatch => {
	const key = new Date().getTime() + Math.random();
	dispatch(enqueueSnackbar({ message: 'Setting password...', options: { key, persist: true, action: () => null } }));

	func_setUserPassword({ email, password })
		.then(res => {
			if (res.data.success) {
				dispatch(enqueueSnackbar({ message: 'Password set', options: { variant: 'success' } }));
			} else {
				throw res.data.message;
			}
		})
		.catch(error => {
			dispatch(enqueueSnackbar({ message: 'Failed to set password', options: { variant: 'error' } }));
		})
		.finally(() => {
			dispatch(closeSnackbar(key));
		});
};

export const setCurrentUserPassword = password => dispatch => {
	const key = new Date().getTime() + Math.random();
	dispatch(enqueueSnackbar({ message: 'Updating password...', options: { key, persist: true, action: () => null } }));

	func_setCurrentUserPassword({ password })
		.then(res => {
			if (res.data.success) {
				dispatch(enqueueSnackbar({ message: 'Password updated', options: { variant: 'success' } }));
			} else {
				throw res.data.message;
			}
		})
		.catch(error => {
			dispatch(enqueueSnackbar({ message: 'Failed to update password', options: { variant: 'error' } }));
		})
		.finally(() => {
			dispatch(closeSnackbar(key));
		});
};

export const sendClientEmails = id => (dispatch, getState) => {
	const state = getState();
	const data = GetClientEmailData(id, state);

	const key = new Date().getTime() + Math.random();
	dispatch(enqueueSnackbar({ message: 'Sending client emails...', options: { key, persist: true, action: () => null } }));

	func_sendClientEmails(data)
		.then(res => {
			if (res.data.success) {
				dispatch(enqueueSnackbar({ message: 'Client emails sent', options: { variant: 'success' } }));
			} else {
				throw res.data.message;
			}
		})
		.catch(error => {
			dispatch(enqueueSnackbar({ message: 'Failed to send client emails', options: { variant: 'error' } }));
		})
		.finally(() => {
			dispatch(closeSnackbar(key));
		});
};

export const setUserDisabled = (email, disabled) => dispatch => {
	const key = new Date().getTime() + Math.random();
	dispatch(enqueueSnackbar({ message: (disabled ? 'Disabling' : 'Enabling') + ' user...', options: { key, persist: true, action: () => null } }));

	const func = disabled ? func_disableUser : func_enableUser;

	func({ email, disabled })
		.then(res => {
			if (res.data.success) {
				dispatch(enqueueSnackbar({ message: 'User ' + (disabled ? 'disabled' : 'enabled'), options: { variant: 'success' } }));
			} else {
				throw res.data.message;
			}
		})
		.catch(error => {
			dispatch(enqueueSnackbar({ message: 'Failed to ' + (disabled ? 'disable' : 'enable') + ' user', options: { variant: 'error' } }));
		})
		.finally(() => {
			dispatch(closeSnackbar(key));
		});
};

export const archiveInspection = id => dispatch => {
	dispatch(updateInspection(id, { archived: true, archivedDate: new Date() }));
	dispatch(
		enqueueUndoSnackbar({ message: 'Inspection archived' }, () => dispatch(updateInspection(id, { archived: false, archivedDate: deleteField() })))
	);
};

export const getClientSpreadsheetBlobURL = () => (dispatch, getState) => {
	const state = getState();
	const data = GetInspectionExportData(state);

	const rows = [
		[
			'#',
			'ID',
			...insertIf(data.lotType === 'Container', 'Booking #', 'Seal #'),
			'Net Weight',
			'Grade',
			...data.displayFactors.map(f => Abbreviations[f]),
			'Notes'
		]
	];

	rows.push(
		...data.carriers.reduce((acc, c) => {
			if (!c.isAverage)
				acc.push([
					c.orderId,
					c.carNumber,
					...insertIf(data.lotType === 'Container', c.bookingNumber, c.sealNumber),
					c.netWeight,
					c.grade,
					...data.displayFactors.map(f => c[f] != null ? c[f] : ''),
					c.clientNotes
				]);
			return acc;
		}, [])
	);

	const csvContent = rows.reduce((acc, v) => acc + v.map(quoteString).join(',') + '\r\n', '');

	const csvBlob = new Blob([csvContent], { type: 'text/csv' });

	return URL.createObjectURL(csvBlob);
};

export function setDefaultLocation(locationId) {
	return dispatch => {
		const batch = writeBatch(firestore);

		getDocs(query(Locations, where('isDefault', '==', true)))
			.then(i => {
				i.docs.forEach(d => batch.update(d.ref, { isDefault: deleteField() }));
				batch.update(doc(Locations, locationId), { isDefault: true });
				batch.commit();
			});
	};
}

export const continueInspection = (inspectionId, email) => async dispatch => {
	dispatch(setCurrentInspection(inspectionId));
	dispatch(changeView('inspection'));

	const res = await func_reassignInspection({ inspectionId, email });

	if (res.data.success !== true) {
		dispatch(genericError('Failed to clear inspection from other users.'));
	}
};

export const enqueueSnackbar = notification => ({
	type: types.ENQUEUE_SNACKBAR,
	notification: {
		...notification,
		key: notification?.options?.key || new Date().getTime() + Math.random()
	}
});

export const enqueueUndoSnackbar = (notification, undoAction) => dispatch =>
	dispatch({
		type: types.ENQUEUE_SNACKBAR,
		notification: {
			...notification,
			key: notification?.options?.key || new Date().getTime() + Math.random(),
			options: {
				...notification.options,
				action: key => (
					<>
						<Button
							color="inherit"
							onClick={() => {
								undoAction();
								dispatch(closeSnackbar(key));
							}}>
							Undo
						</Button>
						<Button color="inherit" onClick={() => dispatch(closeSnackbar(key))}>
							Dismiss
						</Button>
					</>
				)
			}
		}
	});

export const closeSnackbar = key => ({
	type: types.CLOSE_SNACKBAR,
	dismissAll: !key,
	key
});

export const removeSnackbar = key => ({
	type: types.REMOVE_SNACKBAR,
	key
});
