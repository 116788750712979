import archiveInspections from './archiveInspections';
import currentFactor from './currentFactor';
import currentInspection from './currentInspection';
import config from './config';
import factorScroll from './factorScroll';
import initialized from './initialized';
import inspections from './inspections';
import locations from './locations';
import user from './user';
import users from './users';
import view from './view';
import notifications from './notifications';
import { combineReducers } from 'redux';

const inspectorLink = combineReducers({
	config,
	initialized,
	user,
	users,
	inspections,
	currentInspection,
	currentFactor,
	factorScroll,
	view,
	locations,
	archiveInspections,
	notifications
});

export default inspectorLink;
