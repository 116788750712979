import React from 'react';
import { Dropdown, Menu } from 'antd';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton } from '@mui/material';

const MenuButton = ({ children, className, disabled, ...props }) => (
	<Dropdown disabled={disabled} placement="bottomRight" trigger={['click']} overlay={<Menu>{children}</Menu>}>
		<IconButton size='small' {...props}>
			<MoreHorizIcon />
		</IconButton>
	</Dropdown>
);

export default MenuButton;
