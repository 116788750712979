import React from 'react';
import classNames from 'clsx';
import { polyfill } from 'react-lifecycles-compat';

class FlashChange extends React.Component {
	static defaultProps = {
		flashDuration: 200,
		flashStyle: {},
		flashClassName: undefined,
		outerElementType: 'div',
		compare: (prevProps, newProps) => {
			return prevProps.value !== newProps.value;
		},
	};

	state = {
		activeFlash: false,
		props: this.props,
	};

	_timer;

	_deactivateTimer = () => {
		this.setState({
			activeFlash: false,
		});
	};

	_activateTimer() {
		const { flashDuration } = this.props;

		if (this._deactivateTimer) {
			clearTimeout(this._deactivateTimer);
		}

		this._timer = setTimeout(this._deactivateTimer, flashDuration);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const { compare } = nextProps;
		const result = compare(prevState.props, nextProps);
		if (result) {
			return { activeFlash: true, compareResult: result, props: nextProps };
		}
		return { props: nextProps };
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.activeFlash) {
			this._activateTimer();
		}
	}

	componentWillUnmount() {
		if (this._timer) {
			clearTimeout(this._timer);
		}
	}

	render() {
		const { style, className, children, flashClassName, flashStyle, outerElementType } = this.props;
		const { activeFlash, compareResult } = this.state;

		let styleProp = { ...style };
		if (activeFlash) {
			styleProp = { ...styleProp, ...flashStyle };
		}

		const OuterElement = outerElementType;

		return (
			<OuterElement
				{...{
					style: styleProp,
					className: classNames(
						className,
						{ [flashClassName]: activeFlash },
						{ [compareResult]: activeFlash }
					),
				}}
			>
				{children}
			</OuterElement>
		);
	}
}

polyfill(FlashChange);

export default FlashChange;
