import React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Button, Dialog, DialogHeader, DialogContent, DialogFooter } from 'Controls';

const CompleteInspectionDialog = ({ onAccept, onCancel, open, ...props }) => {
	const [sendEmails, setSendEmails] = React.useState(true);

	return (
		<Dialog onClose={onCancel}>
			<DialogHeader title="Finish Inspection" onClose={onCancel} />
			<DialogContent>
				<Typography gutterBottom>Are you certain you want to finish this inspection?</Typography>
				<FormControlLabel
					sx={{ userSelect: 'none' }}
					label="Send Client Emails"
					control={<Checkbox checked={sendEmails} onChange={e => setSendEmails(e.target.checked)} />}
				/>
			</DialogContent>
			<DialogFooter>
				<Button color="danger" onClick={() => onAccept(sendEmails)}>
					Finish
				</Button>
				<Button autoFocus onClick={onCancel}>
					Cancel
				</Button>
			</DialogFooter>
		</Dialog>
	);
};

export default CompleteInspectionDialog;
