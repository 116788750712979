import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { changeView, initAuth, closeSnackbar } from 'Actions';
import { useComponentWillMount } from 'lib/helpers';
import version from 'lib/version';
import { SnackbarProvider } from 'notistack';
import { getInspection } from 'Selectors';
import { InspectionGrid, InspectionView, NavBar, Login, Notifier } from 'Components';
import AdminView from 'Components/Admin/AdminView';
import SpreadsheetCert from 'Components/spreadsheet-cert';
import CompositeSpreadsheetCert from 'Components/spreadsheet-cert/CompositeCarrierGridCert';
import { Loading } from 'Controls';
import { Button } from '@mui/material';
import useConfig from 'lib/useConfig';
import styles from './App.module.css';

const App = ({ initialized, auth, window, inspectionId, sampleGrouping }) => {
	const dispatch = useDispatch();

	useConfig();
	useComponentWillMount(() => dispatch(initAuth()));

	React.useEffect(() => {
		if (window === 'spreadsheet' && !inspectionId) {
			dispatch(changeView('grid'));
		}
	}, [dispatch, window, inspectionId]);

	return (
		<SnackbarProvider
			hideIconVariant
			maxSnack={3}
			anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			action={key => <Button color="inherit" onClick={() => dispatch(closeSnackbar(key))}>Dismiss</Button>}>
			<Notifier />
			<NavBar />
			{auth === null && (
				<div className={styles.Login}>
					<Login />
					<div className={styles.Version}>v{version}</div>
				</div>
			)}
			{auth !== null && !initialized && <Loading message="Initializing" />}
			{auth !== null && window === 'grid' && <InspectionGrid />}
			{auth !== null && window === 'inspection' && <InspectionView />}
			{auth !== null && window === 'admin' && <AdminView />}
			{auth !== null && window === 'spreadsheet' && inspectionId && sampleGrouping === '5-Car Composite' && <CompositeSpreadsheetCert />}
			{auth !== null && window === 'spreadsheet' && inspectionId && sampleGrouping !== '5-Car Composite' && <SpreadsheetCert />}
		</SnackbarProvider>
	);
};

export default connect(state => ({
	window: state.view.window,
	inspectionId: state.currentInspection.id,
	sampleGrouping: getInspection(state).inspection?.sampleGrouping,
	auth: state.user.auth,
	initialized: state.initialized
}))(App);
