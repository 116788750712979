import React from 'react';
import moment from 'moment';

export const DateTimeFormatter = time => (time ? moment(time).format('M/D/YYYY h:mm A') : '');
export const DateFormatter = time => (time ? moment(time).format('M/D/YYYY') : '');

export const StringComparer = key => (a, b) => (a[key] || '').toString().localeCompare((b[key] || '').toString(), 'en', { sensitivity: 'base' });
export const TimeComparer = key => (a, b) => moment(a[key] || new Date()).diff(moment(b[key] || new Date()));

export const filterCollection = (filterValue, items) => {
	if (!filterValue) return items;
	const filter = filterItem(filterValue);
	return items.filter(i => filter(i));
};

const filterItem = filter => item => {
	return Object.values(item).reduce((containsFilter, value) => {
		return (
			containsFilter ||
			(value &&
				value
					.toString()
					.toLowerCase()
					.includes(filter.toLowerCase()))
		);
	}, false);
};

export const onEnterKeyDown = callback => e => {
	if (e.key === 'Enter') callback();
};

const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const IsValidEmail = address => emailRegex.test(address);

export const truncator = (numToTruncate, intDecimalPlaces) => {
	return Number(Math.round(numToTruncate + "e+" + intDecimalPlaces) + "e-" + intDecimalPlaces);
};

const shift = (number, precision, reverseShift) => {
	if (reverseShift) {
		precision = -precision;
	}

	const numArray = ('' + number).split('e');
	return +(numArray[0] + 'e' + (numArray[1] ? +numArray[1] + precision : precision));
};

export const round = (number, precision) => {
	return shift(Math.round(shift(number, precision, false)), precision, true);
};

export function readFile(inputFile) {
	const reader = new FileReader();

	return new Promise((resolve, reject) => {
		reader.onerror = () => {
			reader.abort();
			reject(new DOMException('Problem parsing input file.'));
		};

		reader.onload = () => {
			resolve(reader.result);
		};
		reader.readAsText(inputFile);
	});
}

export const insertIf = (condition, ...elements) => (condition ? elements : []);
export const quoteString = string => '"' + (typeof string === 'string' ? string.replace('"', '""') : string) + '"';

export const isNumeric = num => !isNaN(parseFloat(num)) && isFinite(num);

export const calculatePercentMulti = (numerator, denominator, precision) => {
	const percent = parseFloat((numerator / denominator) * 100);
	return !isNaN(percent) && percent !== Infinity ? round(truncator(percent, precision), precision).toFixed(precision) : '0.00';
};

export const useComponentDidMount = func => React.useEffect(func, []);
export const useComponentWillMount = func => {
	const willMount = React.useRef(true);

	if (willMount.current) {
		func();
	}

	useComponentDidMount(() => {
		willMount.current = false;
	});
};

export const createInspection = ({
	complete = false,
	archived = false,
	deleted = false,
	arrivalTime = new Date(),
	departureTime = null,
	commodityGroup = '',
	commodityType = '',
	locationValue = '142080',
	inspectionType = '',
	inspector = '',
	lotType = '',
	sampler = '',
	sampleMethod = 'DT',
	sampleGrouping = '',
	sampleProbeDepth = '',
	sampleType = '',
	stowage = false
}) => ({
	complete,
	archived,
	deleted,
	arrivalTime,
	departureTime,
	commodityGroup,
	commodityType,
	locationValue,
	inspectionType,
	inspector,
	lotType,
	sampler,
	sampleMethod,
	sampleGrouping,
	sampleProbeDepth,
	sampleType,
	stowage
});

export const createLocation = ({ email = [], company = '', location = '' }) => ({
	email,
	company,
	location
});

export const createCarrier = ({
	inspectionId = null,
	rexId = null,
	offId = null,
	carNumber = '',
	orderId = 1,
	inspector = '',
	netWeight = '',
	creationDate = new Date(),
	...rest
}) => ({
	inspectionId,
	rexId,
	offId,
	carNumber,
	orderId,
	inspector,
	creationDate,
	...rest
});
