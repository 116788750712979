import { calculate, CalculateGrade, CalculateCommodity, CalculateSpecialGrade } from '../models/grading';
import { getCommodityFactors } from './dataHelper';
import Mycotoxins from '../models/mycotoxins';
import { truncator, round } from './helpers';
import moment from 'moment';
import pick from 'lodash/pick';
import converter from 'json-2-csv';

const RemapObjectKeys = (obj, mappings) => {
	Object.keys(mappings).forEach(i => {
		Object.defineProperty(obj, mappings[i], Object.getOwnPropertyDescriptor(obj, i));
		delete obj[i];
	});
};

const CollectionToArray = collection => collection.allIds.map(id => ({ ...collection.byId[id] }));

export const GetTeamData = async state => {
	const inspectionId = state.currentInspection.id;
	const inspection = state.inspections.byId[inspectionId];

	const factors = getCommodityFactors(inspection.commodityGroup);

	const filteredCarrierIds = state.currentInspection.carriers.allIds.filter((id) => state.currentInspection.carriers.byId[id]?.inspectionId === inspectionId);
	const filteredRexIds = state.currentInspection.rex.allIds.filter((id) => state.currentInspection.rex.byId[id]?.inspectionId === inspectionId);
	const filteredOffIds = state.currentInspection.off.allIds.filter((id) => state.currentInspection.off.byId[id]?.inspectionId === inspectionId);

	const carriersTemp = { allIds: filteredCarrierIds, byId: filteredCarrierIds.reduce((a, b) => ({ ...a, [b]: state.currentInspection.carriers.byId[b] }), {}) };
	const rexTemp = { allIds: filteredRexIds, byId: filteredRexIds.reduce((a, b) => ({ ...a, [b]: state.currentInspection.rex.byId[b] }), {}) };
	const offTemp = { allIds: filteredOffIds, byId: filteredOffIds.reduce((a, b) => ({ ...a, [b]: state.currentInspection.off.byId[b] }), {}) };

	let carriers = CollectionToArray(carriersTemp);
	const rex = CollectionToArray(rexTemp);
	const off = CollectionToArray(offTemp);

	let calculatedInspections = calculateCarriers(
		carriers.filter(c => c.isComplete),
		inspection,
		factors
	);
	let calculatedRex = calculateCarriers(rex, inspection, factors).map(i => ({ ...i, status: 'REX' }));
	let calculatedOff = calculateCarriers(off, inspection, factors).map(i => ({ ...i, status: 'OFF' }));

	calculatedRex.sort((a, b) => a.orderId - b.orderId || a.creationDate?.toDate() - b.creationDate?.toDate());
	calculatedOff.sort((a, b) => a.orderId - b.orderId || a.creationDate?.toDate() - b.creationDate?.toDate());
	calculatedInspections.sort((a, b) => a.orderId - b.orderId);

	if (inspection.sampleGrouping === '5-Car Average') {
		calculatedInspections = calculateXCarAverages(5, calculatedInspections, factors);
	}

	const allCarriers = calculatedInspections.concat(calculatedRex, calculatedOff);

	allCarriers.forEach(i => RemapObjectKeys(i, Mappings));

	return await converter.json2csvPromisified(allCarriers, {
		sortHeader: true,
		delimiter: { wrap: '"', eol: '\r\n' }
	});
};

const CalculateSingleCarrier = (carrier, inspection, factors) => {
	let result = {};

	DataPoints.forEach(i => {
		result[i] = '';
	});

	Object.keys(Mycotoxins).forEach(i => {
		result['myco_val_' + i] = carrier[i] && carrier[i].value ? carrier[i].value : '';
		result['myco_unit_' + i] = carrier[i] && carrier[i].units ? carrier[i].units : '';
		result['myco_insp_' + i] = carrier[i] && carrier[i].inspector ? carrier[i].inspector : '';
	});

	result = Object.assign(result, carrier);

	Object.keys(factors).forEach(prop => {
		const factor = factors[prop];
		let value = result[prop];
		if (!factors[prop].allowEmpty && value !== undefined || factors[prop].allowEmpty && value != null) {
			if (factor.type === 'percent') {
				value = (value / (result['w' + factor.sampleSize] || 1)) * 100;
			} else if (factor.type === 'mod') {
				let test = result[factor.factor];
				if (factors[factor.factor].truncate) {
					test = truncator(test, factors[factor.factor].truncate);
					test = round(test, factors[factor.factor].truncate - 1).toFixed(factors[factor.factor].truncate - 1);
				}
				value = calculate[factor.operator](result[factor.base], test);
			}

			if (factor.truncate) {
				value = truncator(value, factor.truncate);
				if (factor.round !== false) {
					value = round(value, factor.truncate - 1).toFixed(factor.truncate - 1);
				} else {
					value = value.toFixed(factor.truncate);
				}
			}
		}

		if (value) {
			Object.assign(result, { ['factor_' + prop]: value, [prop]: value });
		}
	});

	const composite = Object.keys(factors).reduce((acc, prop) => {
		if (factors[prop].type !== 'composite') return acc;

		if (factors[prop].require === 'all') {
			for (let f of factors[prop].factors) {
				if (!result[f]) return { ...acc, [prop]: null };
			}
		}

		let value = factors[prop].factors.reduce((a, b) => {
			if (result[b]) return a + parseFloat(result[b]);
			else return a;
		}, 0);

		if (factors[prop].truncate) {
			value = truncator(value, factors[prop].truncate);
			if (factors[prop].round !== false) {
				value = round(value, factors[prop].truncate - 1).toFixed(factors[prop].truncate - 1);
			} else {
				value = value.toFixed(factors[prop].truncate);
			}
		}

		return { ...acc, ['factor_' + prop]: value, [prop]: value };
	}, {});

	Object.assign(result, composite);

	const commodity = CalculateCommodity(result, inspection.commodityType, factors);
	const grade = CalculateGrade(result, factors);
	const specialGrade = CalculateSpecialGrade(result, factors);

	const sampleValues = GetFactorResults(factors.calculation.sampleDisplay, result, factors);
	const specialValues = GetFactorResults(factors.calculation.special, result, factors);
	const otherValues = GetFactorResults(factors.calculation.other, result, factors);

	result = Object.assign(result, {
		commodity: `${commodity} ${inspection.commodityGroup}`,
		...sampleValues,
		...specialValues,
		...otherValues,
		grade_special: specialGrade,
		grade: grade,
		locationValue: inspection.locationValue,
		lotType: inspection.lotType,
		sampleMethod: inspection.sampleMethod,
		stowage: inspection.stowage,
		inspectionDate: moment(result.inspectionDate?.toDate()).format('MM/DD/YYYY')
	});

	return pick(result, DataPoints);
};

const calculateCarriers = (carriers, inspection, factors) => {
	return carriers.map(i => {
		return CalculateSingleCarrier(i, inspection, factors);
	});
};

const GetFactorResults = (list, inspection, factors) => {
	if (!list) return {};
	return list.reduce((acc, f) => {
		if (factors[f].type === 'select' && inspection[f] && inspection[f].length > 0) {
			return { ...acc, ['factor_' + f]: inspection[f].join(',') };
		} else if (inspection[f] > 0) {
			const val = factors[f].type === 'percent' ? inspection[f] + '%' : inspection[f];
			return { ...acc, ['factor_' + f]: val };
		} else return { ...acc, ['factor_' + f]: '' };
	}, {});
};

const calculateXCarAverages = (x, carriers, factors) => {
	const nodes = [];
	const results = [];

	carriers.forEach((val, i) => {
		const pos = parseInt(i / x, 10);

		if (!nodes[pos]) {
			nodes[pos] = [];
		}

		nodes[pos].push(val);
	});

	nodes.forEach((val, i) => {
		const avg = calculateAverages(factors, val);
		const row = i * x;
		results.push(...val, { ...avg, orderId: `${row + 1}|${row + val.length}`, status: 'AVG' });
	});

	return results;
};

const calculateAverages = (factors, inspections) => {
	if (!factors) return {};

	let data = {};

	for (const i of DataPoints) {
		data[i] = '';
	}

	for (const key of factors.calculation.averages) {
		let count = 0;
		const sum = Object.keys(inspections).reduce((a, b) => {
			let val = inspections[b]['factor_' + key];
			if (val === undefined) return a;

			val = parseFloat(val);
			if (isNaN(val)) return a;

			count++;
			return a + val;
		}, 0);

		data['factor_' + key] = truncator(sum / count, 2).toFixed(1);
	}
	return data;
};

const DataPoints = [
	'carNumber',
	'inspectionDate',
	'netWeight',
	'inspector',
	'orderId',
	'locationValue',
	'lotType',
	'commodity',
	'grade',
	'grade_special',
	'stowage',
	'status',
	'sampleMethod',
	'sampleProbeDepth',
	'myco_val_afla',
	'myco_val_don',
	'myco_val_zear',
	'myco_val_fumn',
	'myco_unit_afla',
	'myco_unit_don',
	'myco_unit_zear',
	'myco_unit_fumn',
	'myco_insp_afla',
	'myco_insp_don',
	'myco_insp_zear',
	'myco_insp_fumn',
	'factor_testWeight',
	'factor_damage',
	'factor_bcfm',
	'factor_heat',
	'factor_moisture',
	'factor_ocol',
	'factor_ston',
	'factor_glass',
	'factor_crot',
	'factor_cstb',
	'factor_fsub',
	'factor_cbur',
	'factor_anfl',
	'factor_odor',
	'factor_htg',
	'factor_flint',
	'factor_flintDent',
	'factor_lw',
	'factor_oli',
	'factor_waxy',
	'factor_sbk',
	'factor_defects',
	'factor_fm',
	'factor_wocl',
	'factor_cc',
	'factor_dockage',
	'factor_idk',
	'factor_stonWeight',
	'factor_stonCount',
	'factor_erg',
	'factor_lsmOdor',
	'factor_sbal',
	'factor_lsm',
	'factor_smut',
	'factor_tret',
	'factor_ggb',
	'factor_dgb',
	'factor_splits',
	'factor_sboc',
	'factor_pms',
	'factor_skd'
];

const Mappings = {
	factor_flintDent: 'factor_flad',
	factor_flint: 'factor_flin',
	factor_moisture: 'factor_m',
	factor_defects: 'factor_def',
	factor_dockage: 'factor_dgk',
	factor_damage: 'factor_dkt',
	factor_splits: 'factor_spl',
	factor_glass: 'factor_glas',
	factor_ston: 'factor_stonWeight'
	// factor_heat: 'factor_ht'
};
