import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import { firestore } from 'lib/firebase';
import { SET_CONFIG } from 'Actions/types';

const ConfigDoc = doc(collection(firestore, 'config'), 'CORE');

const useConfig = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		const configListener = onSnapshot(ConfigDoc, snapshot => {
			dispatch({ type: SET_CONFIG, data: snapshot.data() });
		});

		return () => configListener?.();
	}, []);
}

export default useConfig;