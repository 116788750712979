import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';
import { Badge, Calendar, Popover } from 'antd';
import { getInspections } from 'Selectors';
import styles from './Admin.module.css';

const inspectionsSelector = createSelector([getInspections], inspections => groupBy(inspections, 'arrivalDate'));

const AdminCalendar = () => {
	const inspections = useSelector(inspectionsSelector);

	const dateCellRender = value => (
		<ul className="events">
			{inspections[value.format('MM/DD/YYYY')]?.map(item => (
				<li key={item.id}>
					<Popover
						title={item.location}
						placement="leftTop"
						trigger="hover"
						content={
							<table className={styles.Info}>
								<tbody>
									<tr>
										<td>Created by:</td>
										<td>{item.createdBy}</td>
									</tr>
									<tr>
										<td>Arrival:</td>
										<td>{item.arrivalTime}</td>
									</tr>
									{item.departureTime && (
										<tr>
											<td>Departure:</td>
											<td>{item.departureTime}</td>
										</tr>
									)}
									{item.commodity && (
										<tr>
											<td>Commodity:</td>
											<td>{item.commodity}</td>
										</tr>
									)}
									{item.complete && !item.clientEmailsSent && (
										<tr className="has-text-danger">
											<td colSpan={2}>Client emails not sent</td>
										</tr>
									)}
								</tbody>
							</table>
						}>
						<Badge status={item.complete ? (item.clientEmailsSent ? 'success' : 'warning') : 'default'} />
						{item.location}
					</Popover>
				</li>
			))}
		</ul>
	);

	return (
		<div className={styles.Container}>
			<div className={styles.Calendar}>
				<Calendar cellRender={dateCellRender} />
			</div>
		</div>
	);
};

export default AdminCalendar;
