import React from 'react';
import { connect } from 'react-redux';
import {
	AppBar,
	Avatar,
	Button,
	Box,
	Toolbar,
	IconButton,
	Typography,
	Popper,
	Grow,
	MenuList,
	MenuItem,
	ClickAwayListener,
	Paper,
	Divider,
	ListItemIcon,
	ListItemText,
	Alert
} from '@mui/material';
import { changeView, logout, newInspection, setCurrentInspection, setCurrentUserPassword, getClientSpreadsheetBlobURL } from 'Actions';
import AddIcon from '@mui/icons-material/Add';
import KeyIcon from '@mui/icons-material/Key';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import ListIcon from '@mui/icons-material/List';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { getUserProfile } from 'Selectors';
import { SetPasswordDialog, NotifyDialog } from 'Components/Dialogs';
import { Modal } from 'Controls';
import { logo as clientLogo } from 'config';
import styles from './NavBar.module.css';
import semver from 'semver';
import version from 'lib/version';

const NavBar = (props) => {
	const anchorRef = React.useRef(null);
	const [open, setOpen] = React.useState(false);
	const [state, setState] = React.useState({ setPassModal: null, getCSVModal: null, CSVData: null });

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	const handleListKeyDown = (event) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	}

	const {
		supportedVersion,
		initialized,
		userDisplay,
		logout,
		view,
		currentInspectionId,
		newInspection,
		changeView,
		setCurrentInspection,
		setCurrentUserPassword,
		getClientSpreadsheetBlobURL
	} = props;

	const { displayName, isSignedIn, isAdmin, isInsp, isClient, isCertificate } = userDisplay;

	const validVersion = supportedVersion == null || semver.satisfies(version, supportedVersion);

	return (
		<>
			<AppBar position="fixed" sx={{ boxShadow: 'none' }}>
				<Toolbar>
					<img src={clientLogo.src} width={32} alt="Eastern Iowa Grain Inspection" />
					{!isSignedIn &&
						<Typography color="inherit" variant="h6" sx={{ ml: 2, userSelect: 'none' }} noWrap>
							InspectorsLink
						</Typography>
					}
					{isSignedIn && isAdmin && view.window === 'grid' && currentInspectionId === null && (
						<IconButton color='inherit' sx={{ ml: 2 }} onClick={() => newInspection()}>
							<AddIcon />
						</IconButton>
					)}
					{isSignedIn && isClient && currentInspectionId !== null && (
						<>
							<IconButton color='inherit' sx={{ ml: 2 }} onClick={() => { changeView('grid'); setCurrentInspection(null); }}>
								<CheckIcon />
							</IconButton>
							<Button color='inherit' sx={{ ml: 1 }} onClick={() => setState(s => ({ ...s, getCSVModal: getClientSpreadsheetBlobURL() }))}>
								Download
							</Button>
						</>
					)}
					{isSignedIn && isInsp && currentInspectionId !== null && view.window !== 'grid' && (
						<IconButton color='inherit' sx={{ ml: 2 }} onClick={() => setCurrentInspection(null)}>
							<CancelIcon />
						</IconButton>
					)}
					{isSignedIn && isInsp && view.window === 'grid' && (
						<IconButton color='inherit' sx={{ ml: 2 }} onClick={() => changeView('inspection')}>
							<CancelIcon />
						</IconButton>
					)}
					{isSignedIn && isAdmin && currentInspectionId !== null && view.window === 'inspection' && (
						<IconButton color='inherit' sx={{ ml: 2 }} onClick={() => { changeView('grid'); setCurrentInspection(null); }}>
							<CheckIcon />
						</IconButton>
					)}
					{isSignedIn && isAdmin && currentInspectionId !== null && view.window !== 'inspection' && (
						<IconButton color='inherit' sx={{ ml: 2 }} onClick={() => changeView('inspection')}>
							<ArrowBackIcon />
						</IconButton>
					)}
					{isSignedIn && !isInsp && currentInspectionId === null && view.window !== 'grid' && (
						<IconButton color='inherit' sx={{ ml: 2 }} onClick={() => changeView('grid')}>
							<ListIcon />
						</IconButton>
					)}
					{isSignedIn && isCertificate && currentInspectionId !== null && view.window === 'spreadsheet' && (
						<IconButton color='inherit' sx={{ ml: 2 }} onClick={() => { changeView('grid'); setCurrentInspection(null); }}>
							<CheckIcon />
						</IconButton>
					)}
					<Box flexGrow={1} />
					{isSignedIn && initialized && (
						<>
							<Typography sx={{ userSelect: 'none' }}>{displayName}</Typography>
							<IconButton color="inherit" sx={{ ml: 1 }} ref={anchorRef} onClick={handleToggle}>
								<Avatar sx={{ width: 36, height: 36 }} />
							</IconButton>
							<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement="bottom-end">
								{({ TransitionProps }) => (
									<Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
										<Paper elevation={4}>
											<ClickAwayListener onClickAway={handleClose}>
												<MenuList sx={{ minWidth: 160 }} autoFocusItem={open} onKeyDown={handleListKeyDown}>
													{isAdmin && (
														<MenuItem onClick={e => { handleClose(e); changeView('admin'); }}>
															<ListItemIcon>
																<SupervisorAccountIcon fontSize="small" />
															</ListItemIcon>
															<ListItemText>Administration</ListItemText>
														</MenuItem>
													)}
													<MenuItem onClick={e => { handleClose(e); setState(s => ({ ...s, setPassModal: true })) }}>
														<ListItemIcon>
															<KeyIcon fontSize="small" />
														</ListItemIcon>
														<ListItemText>Change Password</ListItemText>
													</MenuItem>
													<Divider />
													<MenuItem onClick={e => { handleClose(e); logout(); }}>
														<ListItemIcon>
															<LogoutIcon fontSize="small" />
														</ListItemIcon>
														<ListItemText>Logout</ListItemText>
													</MenuItem>
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						</>
					)}
				</Toolbar>
			</AppBar>
			<Toolbar />
			{!validVersion &&
				<Alert variant='filled' severity="error" sx={{ mt: 1, mx: 1 }}>
					You are currently using an outdated version of this application. Please refresh the page to ensure you receive the latest supported version.
				</Alert>
			}
			<Modal show={isSignedIn && state.setPassModal}>
				<SetPasswordDialog
					onCancel={() => setState(s => ({ ...s, setPassModal: null }))}
					onAccept={password => setCurrentUserPassword(password)}
				/>
			</Modal>
			<Modal show={isSignedIn && state.getCSVModal}>
				<NotifyDialog
					title="Download CSV"
					body={
						<span className={styles.CenterButton}>
							<a className="button is-large is-success" href={state.getCSVModal} download="data.csv">
								Download
							</a>
						</span>
					}
					onClose={() => setState(s => ({ ...s, getCSVModal: null }))}
				/>
			</Modal>
		</>
	);
}

const mapStateToProps = state => {
	const { view, config, initialized } = state;
	return {
		currentInspectionId: state.currentInspection.id,
		userDisplay: getUserProfile(state),
		view,
		initialized,
		supportedVersion: config?.supportedVersion
	};
};
export default connect(mapStateToProps, {
	logout,
	newInspection,
	changeView,
	setCurrentInspection,
	getClientSpreadsheetBlobURL,
	setCurrentUserPassword
})(NavBar);
