import React from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from 'lib/firebase';
import { useComponentDidMount } from 'lib/helpers';
import { ERROR_DOCUMENT_FETCH_FAIL } from 'lib/constants';
import { Button, Loading, Dialog, DialogHeader, DialogContent, DialogFooter } from 'Controls';
import styles from './DownloadDocumentDialog.module.css';

const DownloadDocumentDialog = ({ id, type, onAccept, onCancel, ...props }) => {
	const [url, setUrl] = React.useState();
	const [error, setError] = React.useState(null);

	useComponentDidMount(() => {
		getDownloadURL(ref(storage, 'Inspections/' + id + '.' + type))
			.then(url => setUrl(url))
			.catch(error => setError(error));
	});

	return (
		<Dialog onClose={onCancel} {...props}>
			<DialogHeader title="Download Document" onClose={onCancel} />
			<DialogContent>
				{!url && !error && <Loading message="Fetching" />}
				{url && (
					<p className={styles.CenterButton}>
						<a className="button is-large is-success" href={url} onClick={onCancel}>
							Download
						</a>
					</p>
				)}
				{error && <p className="has-text-danger">{ERROR_DOCUMENT_FETCH_FAIL}</p>}
			</DialogContent>
			<DialogFooter>
				<Button onClick={onCancel}>Close</Button>
			</DialogFooter>
		</Dialog>
	);
};

export default DownloadDocumentDialog;
